import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
    </div>
  </div>
)

export default Footer
